export const ProfilePage = 'profile';
export const PreferencesPage = 'profile/preferences';
export const SessionsPage = 'sessions';
export const PaymentPage = 'payment';
export const RecommendationsPage = 'recommendations';
export const NewRecommendationsPage = 'recommendations/new';
export const NotificationsSettingsPage = 'notifications';
export const BookingsPage = 'bookings';
export const WishlistPage = 'wishlist';
export const RequestsPage = 'requests';
export const LoginPage = 'login';
export const RequestPathname = '/requests/[[...slug]]';
export const AccommodationsPage = 'accommodations';
export const ActivityPathname = '/activities/[id]';
export const ActivitiesPathname = '/activities';
export const BaptismPathname = '/baptism';

export const accommodationRequestConfirmationPath: (
  accommodationPath: string,
  startDate?: string,
  endDate?: string
) => string = (accommodationPath, startDate, endDate) => {
  if (startDate && endDate) {
    return `${accommodationPath}/request/${startDate}/${endDate}`;
  }

  return `${accommodationPath}/request`;
};

export const requestPage: (requestId: string) => string = (requestId) =>
  `${RequestsPage}/${requestId}`;

export const accommodationPage: (accommodationId: string) => string = (accommodationId) =>
  `${AccommodationsPage}/${accommodationId}`;

export const asPath: (pageName: string) => string = (pageName) => `/${pageName}`;
