const LogoutIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0.5625 9.00317H12.375"
        stroke="#8D8D8D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5625 11.8157L12.375 9.00317L9.5625 6.19067"
        stroke="#8D8D8D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.47949 12.4501C3.25353 14.0402 4.54027 15.3235 6.13248 16.0933C7.72468 16.8631 9.52964 17.0745 11.2566 16.6935C12.9836 16.3125 14.5321 15.3613 15.6527 13.9931C16.7732 12.6249 17.4006 10.9193 17.4338 9.15112C17.467 7.38292 16.904 5.65502 15.8355 4.24574C14.7671 2.83646 13.2554 1.82785 11.5439 1.38236C9.83237 0.936875 8.02075 1.08045 6.4008 1.78996C4.78084 2.49948 3.44687 3.73362 2.61374 5.2936"
        stroke="#8D8D8D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoutIcon;
