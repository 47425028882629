/* eslint-disable no-underscore-dangle */
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react';
import BedIcon from 'components/BedIcon';
import BurgerIcon from 'components/BurgerIcon';
import ColetteLogo from 'components/ColetteLogo';
import KeyIcon from 'components/KeyIcon';
import LogoutIcon from 'components/LogoutIcon';
import MagnifierIcon from 'components/MagnifierIcon';
import MessageIcon from 'components/MessageIcon';
import ProfileIcon from 'components/ProfileIcon';

import QuestionMarkIcon from 'components/QuestionMarkIcon';
import { Roles } from 'generated/graphql';
import {
  AccommodationsPage,
  BookingsPage,
  PaymentPage,
  ProfilePage,
  RequestPathname,
  RequestsPage,
  asPath,
} from 'lib/util/routes';
import { Viewer } from 'types';
import HeaderContent from './BurgerHeaderContent';
import BurgerLink from './BurgerLink';

interface Props {
  currentPath: string;
  onLogout: () => void;
  viewer: Viewer;
}

type NavLinkType = {
  action?: () => void;
  active: boolean;
  children: React.ReactNode;
  forGuest?: boolean;
  forHost?: boolean;
  href: string;
  Icon: React.ComponentType<{ className?: string }>;
};

export default function BurgerMenu({ currentPath, onLogout, viewer }: Props): JSX.Element {
  const [open, setOpen] = useState(false);

  const navEntries: NavLinkType[][] = [
    [
      {
        active: currentPath === '/' || currentPath === asPath(AccommodationsPage),
        children:
          viewer.role?.name === Roles.Guest ? (
            <FormattedMessage id="topbar.mobile.search" defaultMessage="Search" />
          ) : (
            <FormattedMessage id="topbar.mobile.my_rooms" defaultMessage="My rooms" />
          ),
        forGuest: true,
        href: '/',
        Icon: MagnifierIcon,
      },
      {
        active: currentPath === '/accommodations',
        children:
          viewer.role?.name === Roles.Guest ? (
            <FormattedMessage id="topbar.mobile.search" defaultMessage="Search" />
          ) : (
            <FormattedMessage id="topbar.mobile.my_rooms" defaultMessage="My rooms" />
          ),
        forHost: true,
        href: '/accommodations',
        Icon: BedIcon,
      },
      {
        active: currentPath === asPath(RequestsPage) || currentPath === RequestPathname,
        children: <FormattedMessage id="topbar.mobile.my_requests" defaultMessage="My requests" />,
        href: asPath(RequestsPage),
        Icon: MessageIcon,
      },
      {
        active: currentPath === asPath(BookingsPage),
        children: <FormattedMessage id="topbar.mobile.my_bookings" defaultMessage="My bookings" />,
        href: asPath(BookingsPage),
        Icon: KeyIcon,
      },
      {
        active: false,
        children: <FormattedMessage id="topbar.mobile.faq" defaultMessage="FAQ" />,
        href: "https://help.colette.club/fr",
        Icon: QuestionMarkIcon
      },
      {
        active: currentPath === asPath(ProfilePage) || currentPath === asPath(PaymentPage),
        children: <FormattedMessage id="topbar.mobile.my_account" defaultMessage="My account" />,
        href: asPath(ProfilePage),
        Icon: ProfileIcon,
      },
    ],
    [
      {
        action: onLogout,
        active: false,
        children: (
          <FormattedMessage
            id="topbar.desktop.account_dropdown.sign_out"
            defaultMessage="Sign out"
          />
        ),
        href: asPath(ProfilePage),
        Icon: LogoutIcon,
      },
    ],
  ];

  return (
    <div className="lg:hidden">
      {/* BurgerMenu button */}
      <div className="flex items-center w-full h-20 pl-7">
        <button
          className="outline-none h-9 w-9 focus:outline-none"
          onClick={() => {
            setOpen(true);
          }}
          type="button"
        >
          <BurgerIcon className={currentPath !== '/' ? 'text-black' : 'text-white'} />
        </button>
        <div className="flex items-center justify-center w-full">
          <HeaderContent currentPath={currentPath} />
        </div>
      </div>
      {/* Modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          {/* Modal background overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-black bg-opacity-80" />
          </Transition.Child>

          {/* Modal content */}
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-200"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="fixed inset-y-0 w-4/5 max-w-md">
              <div className="flex flex-col h-full pt-6 pb-12 overflow-y-auto bg-white">
                <div className="flex items-center px-7">
                  {/* Close button */}
                  <button
                    type="button"
                    className="p-3 outline-none focus:outline-none"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <svg width="18" height="18" viewBox="8 8 18 18" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.48528 8.4853C8.09476 8.87582 8.09476 9.50899 8.48528 9.89951L15.5563 16.9706L8.48528 24.0416C8.09476 24.4322 8.09476 25.0653 8.48528 25.4559C8.8758 25.8464 9.50897 25.8464 9.89949 25.4559L16.9706 18.3848L24.0416 25.4559C24.4322 25.8464 25.0653 25.8464 25.4558 25.4559C25.8464 25.0653 25.8464 24.4322 25.4558 24.0416L18.3848 16.9706L25.4558 9.89951C25.8464 9.50899 25.8464 8.87582 25.4558 8.4853C25.0653 8.09478 24.4322 8.09478 24.0416 8.4853L16.9706 15.5564L9.89949 8.4853C9.50897 8.09478 8.87581 8.09478 8.48528 8.4853Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                  <div className="flex items-center justify-center w-full mr-9">
                    <ColetteLogo />
                  </div>
                </div>
                {/* Menu content */}
                <div
                  className="inset-0 flex flex-col justify-between flex-1 h-full mt-6 px-7"
                  aria-hidden="true"
                >
                  {navEntries.map((group, index) => {
                    return (
                      <div key={index.toString()}>
                        {group.map((link: NavLinkType, idx) => {
                          if (
                            (link.forGuest && viewer.role?.name === Roles.Guest) ||
                            (link.forHost && viewer.role?.name === Roles.Host)
                          ) {
                            return null;
                          }
                          return (
                            <BurgerLink
                              action={link.action}
                              active={link.active}
                              href={link.href}
                              Icon={link.Icon}
                              key={`${link.href}-${idx.toString()}`}
                            >
                              {link.children}
                            </BurgerLink>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                {/* /End Menu content */}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
