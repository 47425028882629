const BurgerIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="36"
    height="18"
    viewBox="0 0 36 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H36V2H0V0ZM0 8H36V10H0V8ZM36 16H0V18H36V16Z"
      className="fill-current"
    />
  </svg>
);

export default BurgerIcon;
