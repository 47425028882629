import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import Link from 'next/link';
import ProgressBar from 'components/ProgressBar';

const TOTAL = 3;

interface Props {
  recommendationRequestCount: number;
  loading: boolean;
}

const Banner = ({ recommendationRequestCount, loading }: Props) => {
  const current = recommendationRequestCount;
  const progress = (current / TOTAL) * 100;

  if (recommendationRequestCount >= TOTAL || loading) {
    return null;
  }

  return (
    <div className="flex items-center justify-center text-white bg-blue-500 font-sans font-medium text-base p-4">
      <Link href={{ pathname: '/recommendations/new' }} className="flex gap-4">
        <FormattedMessage
          id="banner.request_recommendations"
          defaultMessage="Request recommendations"
          tagName="span"
        />

        <div
          className={clsx('text-center hidden mx-auto', {
            'md:block': !loading && progress < 100,
          })}
        >
          <div className="flex flex-nowrap gap-2 items-center">
            <ProgressBar progressPercentage={progress} light className="w-24" />
            <FormattedMessage
              id="banner.request_recommendations.current_requests"
              defaultMessage="{current}/{total} recommendations"
              values={{ current, total: TOTAL }}
              tagName="span"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Banner;
