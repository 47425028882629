import { gql } from '@apollo/client';
import UserRecommendationFields from './UserRecommendationFields.frag';

export default gql`
  query ViewerUserRecommendations {
    viewer {
      id
      recommendations(first: 1000, filter: { status: [PENDING, ACCEPTED] }) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        count
        edges {
          node {
            ...UserRecommendationFields
          }
        }
      }
    }
  }
  ${UserRecommendationFields}
`;
