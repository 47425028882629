/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import Link from 'next/link';

interface Props {
  action?: () => void;
  active: boolean;
  classname?: string;
  children: React.ReactNode;
  href: string;
  Icon: React.ComponentType<{ className?: string }>;
}

const CustomButton = ({ action, classname, children }: Props) => (
  <button
    type="button"
    className={classname}
    onClick={(e) => {
      e.preventDefault();
      e.persist();
      if (action) action();
    }}
    role="menuitem"
  >
    {children}
  </button>
);

const CustomLink = ({ children, classname, href }: Props) => (
  <Link href={href} className={classname}>
    {children}
  </Link>
);

const Wrapper = (props: Props) => {
  const { action } = props;
  if (action) {
    return <CustomButton {...props} />;
  }
  return <CustomLink {...props} />;
};

const BurgerLink = (props: Props) => {
  const { active, children, Icon } = props;
  return (
    <Wrapper
      {...props}
      classname={clsx(
        'h-14 flex items-center text-lg whitespace-nowrap my-2 outline-none focus:outline-none',
        active ? 'bg-gray-200 rounded-2xl text-black' : 'text-gray-600 hover:text-black'
      )}
    >
      <Icon className={clsx('h-5 mx-5', active ? 'text-blue-500' : 'hover:text-blue-500')} />
      <span>{children}</span>
    </Wrapper>
  );
};

export default memo(BurgerLink);
