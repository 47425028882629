const KeyIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={props.className}
  >
    <path
      d="M9.58176 10.5414L11.3916 8.73157L12.6375 9.06581C12.8269 9.11632 13.0263 9.11614 13.2156 9.0653C13.4049 9.01446 13.5775 8.91474 13.7161 8.77613C13.8547 8.63753 13.9544 8.46492 14.0053 8.27561C14.0561 8.08631 14.0563 7.88696 14.0058 7.69757L13.6715 6.45165L14.2291 5.89411L15.475 6.22835C15.6644 6.27909 15.8639 6.27908 16.0533 6.22832C16.2427 6.17756 16.4154 6.07784 16.5541 5.93917C16.6927 5.80051 16.7925 5.6278 16.8432 5.43838C16.894 5.24897 16.894 5.04953 16.8432 4.86011L16.5097 3.61419C16.8239 3.29987 17.0004 2.87361 17.0004 2.42917C17.0003 1.98473 16.8237 1.55852 16.5094 1.2443C16.195 0.930084 15.7688 0.753595 15.3243 0.753662C14.8799 0.753729 14.4537 0.930346 14.1395 1.24466L7.21223 8.1719C6.25759 7.76715 5.19188 7.70702 4.19777 8.00183C3.20365 8.29664 2.34302 8.92802 1.76337 9.78778C1.18371 10.6475 0.921131 11.6821 1.02062 12.7143C1.12011 13.7464 1.57548 14.7118 2.30869 15.445C3.04189 16.1782 4.00728 16.6336 5.0394 16.733C6.07153 16.8325 7.10612 16.5699 7.96588 15.9903C8.82564 15.4106 9.45702 14.55 9.75183 13.5559C10.0466 12.5618 9.98652 11.4961 9.58176 10.5414Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 14C6.32843 14 7 13.3284 7 12.5C7 11.6716 6.32843 11 5.5 11C4.67157 11 4 11.6716 4 12.5C4 13.3284 4.67157 14 5.5 14Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default KeyIcon;
