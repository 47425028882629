import { gql } from '@apollo/client';

export default gql`
  fragment UserRecommendationFields on UserRecommendation {
    id
    about
    recommenderFirstName
    recommenderLastName
    recommenderEmail
    status
    relationship
    published
    tags(first: 10) {
      edges {
        node {
          id
          value
        }
      }
    }
  }
`;
