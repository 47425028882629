import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useViewerQuery } from 'generated/graphql';
import { Viewer } from 'types';

interface WithAuthProps {
  viewer: Viewer;
}

const withAuth = <T extends WithAuthProps = WithAuthProps>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof WithAuthProps>> => {
  const ComponentWithAuth = (props: Omit<T, keyof WithAuthProps>) => {
    const { asPath, push, query } = useRouter();
    // eslint-disable-next-line camelcase
    const { token, user_id } = query;

    const { loading, data } = useViewerQuery({ fetchPolicy: 'cache-and-network' });

    if (!data?.viewer && loading) {
      return <div />;
    }

    const viewer = data?.viewer;

    if (viewer) {
      Sentry.setUser({
        id: viewer.id,
        email: viewer.profile.email,
        username: viewer.profile.firstName + ` ${viewer.profile.lastName} || [undefined]`,
      });
    }

    if (viewer && !viewer.onboarded && !viewer.active && !asPath.startsWith('/onboarding')) {
      if (viewer.role?.name) push(`/onboarding/${viewer.role?.name.toLowerCase()}`);
      else push('/onboarding');
      return null;
    }

    if (viewer && !viewer?.role?.name && !asPath.startsWith('/onboarding')) {
      push('/onboarding');
      return null;
    }

    if (
      viewer &&
      viewer.onboarded &&
      !viewer.eligible &&
      !viewer.active &&
      !asPath.startsWith('/onboarding')
    ) {
      push(`/onboarding/${viewer.role?.name?.toLowerCase()}?step=confirmation`);
      return null;
    }

    if (!viewer) {
      if (token) {
        push({
          pathname: '/auto-login',
          // eslint-disable-next-line camelcase
          query: { redirectTo: asPath, token, ...(user_id && { userId: user_id }) },
        });
      } else {
        push({ pathname: '/login', query: { redirectTo: asPath } });
      }
      return <div />;
    }
    if (!viewer.termsOfServiceAcceptedAt) {
      push('/tos/accept');
      return <div />;
    }

    return <WrappedComponent {...(props as T)} viewer={viewer} />;
  };

  return ComponentWithAuth;
};

export default withAuth;
