const QuestionMarkIcon = ({ fill, width, height, className }: { fill?: string, width?: number, height?: number, className?: string }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width || "21"}
    height={height || "21"}
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    fill={fill}
    className={className}
  >
    <g>
      <path d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490s490-219.4,490-490C990,229.4,770.6,10,500,10z M500,928.7C263.6,928.7,71.3,736.4,71.3,500C71.3,263.6,263.6,71.3,500,71.3c236.4,0,428.8,192.3,428.8,428.7S736.4,928.7,500,928.7z" /><path d="M455.3,734.6c0,24.7,20,44.7,44.7,44.7c24.7,0,44.7-20,44.7-44.7c0-24.7-20-44.7-44.7-44.7C475.3,690,455.3,710,455.3,734.6z" /><path d="M500,214.2c-82.1,0-148.9,66.2-148.9,147.6c0,16.4,13.3,29.8,29.8,29.8c16.4,0,29.8-13.3,29.8-29.8c0-49.4,39.3-88,89.4-88c49.3,0,89.4,40.4,89.4,90c0,19.9-24.9,44.8-49,68.9c-32.9,32.9-70.1,70.1-70.1,118.8v50.4c0,16.4,13.3,29.8,29.8,29.8c16.4,0,29.8-13.4,29.8-29.8v-50.4c0-24,26.7-50.8,52.7-76.6c32.7-32.7,66.5-66.5,66.5-111C648.9,281.3,582.1,214.2,500,214.2z" />
    </g>
  </svg>
)

export default QuestionMarkIcon;
