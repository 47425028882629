import clsx from 'clsx';
import Link from 'next/link';

interface Props {
  href?: string;
  className?: string;
  light?: boolean;
  active: boolean;
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const TopbarButton = ({ className, light, children, href, active, ...props }: Props) => {
  if (href) {
    return (
      <Link
        href={href}
        className={clsx(
          className,
          'topbar-button',
          light && 'topbar-button-light',
          active && 'topbar-button-active'
        )}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <div
      className={clsx(
        className,
        'topbar-button',
        light && 'topbar-button-light',
        active && 'topbar-button-active'
      )}
    >
      {children}
    </div>
  );
};

export default TopbarButton;
