const MagnifierIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.5305 14.3771C14.7126 12.9855 16.1959 9.20308 14.8436 5.92874C13.4912 2.65439 9.81531 1.1281 6.6332 2.51966C3.4511 3.91122 1.9678 7.69368 3.32016 10.968C4.67252 14.2424 8.34843 15.7687 11.5305 14.3771Z" />
    <path d="M13.5083 13.0032L18.3638 18" />
  </svg>
);

export default MagnifierIcon;
