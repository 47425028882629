import { FormattedMessage } from 'react-intl';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Link from 'next/link';
import Avatar from 'components/Avatar';
import { PingDot } from 'components/PingDot';
import { isProfileIncomplete } from 'lib/util/is-profile-incomplete';
import { PaymentPage, ProfilePage, asPath } from 'lib/util/routes';
import { Viewer } from 'types';

const AccountDropdown = ({
  currentPath,
  viewer,
  onLogout,
}: {
  currentPath: string;
  viewer: Viewer;
  onLogout: () => void;
}) => {
  return (
    <div className="z-50 inline-block">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="px-4 py-3 outline-none focus:outline-none">
              <div className="relative flex items-center space-x-3">
                <Avatar className="rounded-full" user={viewer.profile} />
                <span>
                  <FormattedMessage id="topbar.desktop.my_account" defaultMessage="My account" />
                </span>
                {isProfileIncomplete(viewer) ? (
                  <PingDot className="absolute -top-2 -right-3" />
                ) : null}
              </div>
            </Menu.Button>

            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 w-56 px-4 py-5 mt-1 origin-top-right bg-white shadow-lg outline-none cursor-auto rounded-2xl ring-1 ring-black ring-opacity-5 z-50"
              >
                <Menu.Item>
                  {() => (
                    <Link
                      href={asPath(ProfilePage)}
                      className={clsx(
                        currentPath === asPath(ProfilePage) && 'bg-gray-100 font-medium',
                        'block w-full text-left px-4 py-2 font-sans font-light text-base text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100 space-x-3'
                      )}
                    >
                      <>
                        {isProfileIncomplete(viewer) ? <PingDot /> : null}
                        <span>
                          <FormattedMessage
                            id="topbar.desktop.account_dropdown.profile"
                            defaultMessage="Profile"
                          />
                        </span>
                      </>
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {() => (
                    <Link
                      href={asPath(PaymentPage)}
                      className={clsx(
                        currentPath === asPath(PaymentPage) && 'bg-gray-100 font-medium',
                        'block w-full text-left px-4 py-2 font-sans font-light text-base text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100'
                      )}
                    >
                      <FormattedMessage
                        id="topbar.desktop.account_dropdown.payment"
                        defaultMessage="Payment"
                      />
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item>
                  <button
                    onClick={onLogout}
                    className="block w-full px-4 py-2 font-sans text-base font-light text-left text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    role="menuitem"
                  >
                    <FormattedMessage
                      id="topbar.desktop.account_dropdown.sign_out"
                      defaultMessage="Sign out"
                    />
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default AccountDropdown;
