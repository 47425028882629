import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import ViewerUserRecommendationsQuery from 'graphql/ViewerUserRecommendations';
import { actions as notificationActions } from 'lib/redux/notifications';
import Component from './component';

const { addNotifications } = notificationActions;

const UserRecommendationBanner = () => {
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(ViewerUserRecommendationsQuery);

  if (error) {
    dispatch(addNotifications([{ severity: 'error', message: error.message }]));
  }

  return (
    <Component
      loading={loading}
      recommendationRequestCount={data?.viewer?.recommendations?.count}
    />
  );
};

export default UserRecommendationBanner;
