import clsx from 'clsx';

export function PingDot(props: React.ComponentProps<'span'>) {
  return (
    <span {...props} className={clsx('h-2 w-2 relative inline-flex', props.className)}>
      <span className="absolute inline-flex w-full h-full bg-red-500 rounded-full opacity-75 animate-ping" />
      <span className="relative inline-flex w-2 h-2 bg-red-500 rounded-full" />
    </span>
  );
}
