import { Viewer } from 'types';

export const isProfileIncomplete = (viewer: Viewer) => {
  if (
    !viewer.profile.birthdate ||
    !viewer.profile.avatar?.url ||
    !viewer.profile.video?.url ||
    !viewer.receivedRecommendationCount
  ) {
    return true;
  }

  return false;
};
