import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import ColetteLogo from 'components/ColetteLogo';
import {
  AccommodationsPage,
  BookingsPage,
  PaymentPage,
  ProfilePage,
  RequestPathname,
  RequestsPage,
  asPath,
  WishlistPage,
} from 'lib/util/routes';

interface Props {
  currentPath: string;
}

const BurgerHeaderContent = ({ currentPath }: Props): JSX.Element => {
  if (currentPath === asPath(RequestsPage) || currentPath === RequestPathname) {
    return (
      <h2 className="content-header mr-9">
        <FormattedMessage id="topbar.mobile.my_requests" defaultMessage="My requests" />
      </h2>
    );
  }
  if (currentPath === asPath(BookingsPage)) {
    return (
      <h2 className="content-header mr-9">
        <FormattedMessage id="topbar.mobile.my_bookings" defaultMessage="My bookings" />
      </h2>
    );
  }
  if (currentPath === asPath(WishlistPage)) {
    return (
      <h2 className="content-header mr-9">
        <FormattedMessage id="topbar.mobile.my_wishlist" defaultMessage="My wishlist" />
      </h2>
    );
  }
  if (currentPath === asPath(ProfilePage) || currentPath === asPath(PaymentPage)) {
    return (
      <h2 className="content-header mr-9">
        <FormattedMessage id="topbar.mobile.my_account" defaultMessage="My account" />
      </h2>
    );
  }
  if (currentPath === asPath(AccommodationsPage)) {
    return <div id="burger-header-content" />;
  }
  return <ColetteLogo className="text-white mr-9" />;
};

export default memo(BurgerHeaderContent);
