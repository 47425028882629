import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { z } from 'zod';
import { Accommodation } from 'generated/graphql';
import { AccommodationRecommendation, AccommodationQuery, SavedAccommodation } from 'types';

export const isUrl: (url: string) => boolean = (url) => {
  const result = z.string().url().safeParse(url);
  return result.success;
};

export const isNewAccommodation = (
  accommodation?:
    | Accommodation
    | AccommodationRecommendation
    | AccommodationQuery
    | SavedAccommodation
    | null
): boolean => !!accommodation?.new;

export const negativeMod: (n: number, m: number) => number = (n, m) => {
  const q = n % m;
  return q < 0 ? q + m : q;
};

export const queryItemToArray: (item: string | string[] | undefined) => any[] = (item) => {
  if (typeof item === 'undefined') {
    return [];
  }

  if (Array.isArray(item)) {
    return item;
  }

  return [item];
};

const isEmptyString = (value: any) => isEqual(value, '');

export const clearQueryObject: (query: Record<string, any>) => Record<string, any> = (query) =>
  omitBy(omitBy(query, isNil), isEmptyString);

export const isBoolean: (val: any) => boolean = (val) => typeof val === 'boolean';

export const sortPicturesByFeatured = <T extends NonNullable<Accommodation['pictures']>>(
  pictures: T
) => {
  const featuredIndex = pictures.findIndex((picture) => picture.isFeatured);

  if (featuredIndex > -1) {
    return [
      pictures[featuredIndex],
      ...pictures.slice(0, featuredIndex),
      ...pictures.slice(featuredIndex + 1),
    ];
  }

  return pictures as T;
};

export { default as login } from './login';
export { default as logout } from './logout';

type NodeArrays =
  | {
      edges?: ({ node?: Record<string, any> | null | undefined } | null)[] | null | undefined;
    }
  | undefined
  | null;

export type ConnectionResultToNodeArray<T extends NodeArrays> = NonNullable<
  NonNullable<NonNullable<NonNullable<T>['edges']>[number]>['node']
>[];

export const connectionResultToNodeArray = <T extends NodeArrays>(connectionResult: T) => {
  const array = connectionResult?.edges || [];

  const nodes = array.map((item: any) => item.node);

  return nodes as ConnectionResultToNodeArray<T>;
};

export const relayCursorToInt: (cursor: string) => number = (cursor) => {
  const buff = Buffer.from(cursor, 'base64');
  const text = buff.toString('utf-8').replace('arrayconnection:', '');
  return parseInt(text);
};

export const addQueryString: (url: string, queryParams: Record<string, any>) => string = (
  url,
  queryParams
) => {
  const queryString = Object.keys(queryParams)
    .filter((key: any) => !!queryParams[key])
    .map((key: any) => `${key}=${queryParams[key]}`)
    .join('&');

  if (queryString.length > 0) {
    return `${url}?${queryString}`;
  }

  return url;
};

export const getPageFromQueryCursor: (query: Record<string, any>, perPage: number) => number = (
  query,
  perPage
) => {
  if (query.after) {
    const decodedCursor = atob(query.after);
    const offset = parseInt(decodedCursor.split(':')[1]);
    return (offset + 1) / perPage + 1;
  }
  if (query.before) {
    const decodedCursor = atob(query.before);
    const offset = parseInt(decodedCursor.split(':')[1]);
    return Math.max(offset, 0) / perPage;
  }

  return 1;
};
