import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Image as ImageComp } from 'components/Image';
import { User } from 'generated/graphql';
import { AccommodationRecommendation, AccommodationQuery, Viewer } from 'types';

interface Props {
  className?: string;
  user:
  | Viewer['profile']
  | User['profile']
  | AccommodationQuery['owner']['profile']
  | AccommodationRecommendation['owner']['profile'];
}

const useLoaded: (src?: string | null) => boolean | 'error' | 'loaded' = (src) => {
  const [loaded, setLoaded] = useState<boolean | 'error' | 'loaded'>(false);

  useEffect(() => {
    if (!src) {
      return undefined;
    }

    setLoaded(false);

    let active = true;
    const image = new Image();
    image.src = src;

    image.onload = () => {
      if (!active) {
        return;
      }
      setLoaded('loaded');
    };

    image.onerror = () => {
      if (!active) {
        return;
      }
      setLoaded('error');
    };

    return () => {
      active = false;
    };
  }, [src]);

  return loaded;
};

const Avatar = ({ className, user }: Props) => {
  const imageUrl = user.avatar?.url;
  const loaded = useLoaded(imageUrl);
  const hasImgNotFailing = imageUrl && loaded !== 'error';

  let children: React.ReactNode | null = null;

  if (imageUrl && hasImgNotFailing) {
    children = (
      <div className={clsx(className, 'relative flex-shrink-0 inline-block h-8 w-8')}>
        <ImageComp
          className={clsx(className, 'object-cover')}
          alt={user.firstName?.[0]?.toUpperCase() || ''}
          src={imageUrl}
          fill
        />
      </div>
    );
  } else {
    children = (
      <span
        className={clsx(
          className,
          'inline-flex items-center justify-center h-8 w-8 bg-gray-400 text-xs font-medium text-white'
        )}
      >
        {user.firstName?.[0]?.toUpperCase()}
      </span>
    );
  }

  return <>{children}</>;
};

export default Avatar;
