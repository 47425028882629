import { FormattedMessage } from 'react-intl';
import Link from 'next/link';

interface Props {
  completionScore: number;
}

export const KycBanner = ({ completionScore }: Props) => (
  <Link href="/kyc">
    <div className="flex items-center justify-center text-white bg-yellow-600 font-sans font-medium text-base p-4 cursor-pointer">
      {completionScore === 100 ? (
        <FormattedMessage id="kyc.banner.verifying" />
      ) : (
        <FormattedMessage id="kyc.banner" />
      )}
    </div>
  </Link>
);
