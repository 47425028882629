import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import Link from 'next/link';
import BedIcon from 'components/BedIcon';
import ColetteLogo from 'components/ColetteLogo';
import { HeartOutlineIcon } from 'components/HeartOutlineIcon';
import KeyIcon from 'components/KeyIcon';
import MagnifierIcon from 'components/MagnifierIcon';
import MessageIcon from 'components/MessageIcon';
import QuestionMarkIcon from 'components/QuestionMarkIcon';
import Skeleton from 'components/Skeleton';
import TopbarButton from 'components/TopbarButton';
import UnreadPill from 'components/UnreadPill';
import { Roles } from 'generated/graphql';
import {
  asPath,
  BookingsPage,
  PaymentPage,
  ProfilePage,
  RequestPathname,
  RequestsPage,
  WishlistPage,
} from 'lib/util/routes';
import { Viewer } from 'types';
import AccountDropdownMenu from './AccountDropdownMenu';

interface TopbarProps {
  currentPath: string;
  border?: boolean;
  light?: boolean;
  nUnread: number;
  showWelcome?: boolean;
  viewer: Viewer;
  onLogout: () => void;
  backgroundImage?: string;
}

const Topbar = ({
  currentPath,
  border,
  light,
  nUnread,
  showWelcome,
  viewer,
  onLogout,
  backgroundImage,
}: TopbarProps) => {
  const [innerFirstName, setInnerFirstName] = useState<string | null | undefined>(viewer.profile.firstName);

  useEffect(() => {
    setInnerFirstName(viewer.profile.firstName);
  }, [viewer]);

  return (
    <div
      className={clsx('hidden lg:block p-6 bg-cover', {
        'border-b border-gray-200': border,
        'text-black md:bg-white': light,
        'text-white md:bg-transparent': !light,
      })}
      style={{ backgroundImage: backgroundImage ? `url('${backgroundImage}')` : undefined }}
    >
      <div className="container flex items-center m-auto">
        <Link href="/">
          <ColetteLogo className="w-24 h-auto -mt-1" />
        </Link>

        <div className="flex-grow ml-12 space-x-2">
          {viewer.role?.name === Roles.Guest && (
            <TopbarButton active={currentPath === '/'} href="/" light={light}>
              <MagnifierIcon className="inline-block mr-2 text-blue-500" />
              <FormattedMessage id="topbar.desktop.search" defaultMessage="Search" />
            </TopbarButton>
          )}

          {viewer.role?.name === Roles.Host && (
            <TopbarButton
              active={currentPath === '/accommodations'}
              href="/accommodations"
              light={light}
            >
              <BedIcon className="inline-block mr-2 text-blue-500" />
              <FormattedMessage id="topbar.desktop.my_rooms" defaultMessage="My rooms" />
            </TopbarButton>
          )}

          <TopbarButton
            active={currentPath === asPath(RequestsPage) || currentPath === RequestPathname}
            href={asPath(RequestsPage)}
            light={light}
          >
            <MessageIcon className="inline-block mr-2 text-blue-500" />
            <FormattedMessage id="topbar.desktop.my_requests" defaultMessage="My requests" />
            <UnreadPill n={nUnread} />
          </TopbarButton>

          <TopbarButton
            active={currentPath === asPath(BookingsPage)}
            href={asPath(BookingsPage)}
            light={light}
          >
            <KeyIcon className="inline-block mr-2 text-blue-500" />
            <FormattedMessage id="topbar.desktop.my_bookings" defaultMessage="My bookings" />
          </TopbarButton>



          {viewer.role?.name === Roles.Guest ? (
            <TopbarButton
              active={currentPath === asPath(WishlistPage)}
              href={asPath(WishlistPage)}
              light={light}
            >
              <HeartOutlineIcon className="inline-block mr-2 text-blue-500 w-5 h-5" />
              <FormattedMessage id="topbar.desktop.my_wishlist" defaultMessage="My wishlist" />
            </TopbarButton>
          ) : null}

          <TopbarButton
            active={false}
            href="https://help.colette.club/fr"
            light={light}
          >
            <QuestionMarkIcon className="inline-block mr-2 text-blue-500 w-5 h-5" fill={"rgb(152 191 224)"} />
            <FormattedMessage id="topbar.desktop.faq" defaultMessage="FAQ" />
          </TopbarButton>
        </div>

        <div className="text">
          <TopbarButton
            className="p-0"
            active={currentPath === asPath(ProfilePage) || currentPath === asPath(PaymentPage)}
            light={light}
          >
            <AccountDropdownMenu currentPath={currentPath} viewer={viewer} onLogout={onLogout} />
          </TopbarButton>
        </div>
      </div>

      <div
        className={clsx(
          'container flex items-center h-32 m-auto text-2xl font-medium text-white overflow-hidden transition-all duration-300',
          { 'max-h-full': showWelcome, 'max-h-none': !showWelcome }
        )}
      >
        <div className="font-serif text-3xl font-medium">
          {innerFirstName ? (
            <FormattedMessage
              id="topbar.desktop.welcome_coliving"
              defaultMessage="<line>Hi {firstName},</line><line>How's your cohabitation going ?</line>"
              values={{
                line: (chunk) => <div>{chunk}</div>,
                firstName: innerFirstName,
              }}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
