import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import BurgerMenu from 'components/BurgerMenu';
import { KycBanner } from 'components/KycBanner';
import Notifications from 'components/Notifications';
import Topbar from 'components/Topbar';
import UserRecommendationBanner from 'components/UserRecommendationBanner';
import { Roles } from 'generated/graphql';
import { cn } from 'lib/cn';
import { logout } from 'lib/util';
import { isGuestOrHost } from 'lib/util/is-active-viewer';
import { LoginPage, asPath } from 'lib/util/routes';
import { Viewer } from 'types';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  border?: boolean;
  light?: boolean;
  showWelcome?: boolean;
  viewer: Viewer;
  backgroundImage?: React.ReactNode;
  topbarImage?: string;
}

export default function Layout({
  children,
  viewer,
  border,
  light,
  showWelcome,
  backgroundImage,
  topbarImage,
  ...props
}: Props) {
  const { pathname, push } = useRouter();
  const apolloClient = useApolloClient();

  async function handleLogout() {
    await logout();
    apolloClient.cache.evict({ id: `Viewer:${viewer.id}` });
    push(asPath(LoginPage));
  }

  return (
    <div {...props} className={cn('flex flex-col h-full', props.className)}>
      {viewer.role?.name === Roles.Guest && !viewer.active ? (
        <KycBanner completionScore={viewer.profile.completionScore} />
      ) : (
        <UserRecommendationBanner />
      )}

      <BurgerMenu currentPath={pathname} viewer={viewer} onLogout={handleLogout} />
      <Topbar
        border={border}
        light={light}
        nUnread={isGuestOrHost(viewer.role) ? viewer.role.bookings?.count || 0 : 0}
        showWelcome={showWelcome}
        viewer={viewer}
        currentPath={pathname}
        onLogout={handleLogout}
        backgroundImage={topbarImage}
      />
      {children}
      <Notifications />
      {backgroundImage ? <div className="fixed inset-0 -z-10">{backgroundImage}</div> : null}
    </div>
  );
}
