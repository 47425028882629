const ProfileIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.5192 19.6803C15.7766 19.6803 20.0385 15.6509 20.0385 10.6803C20.0385 5.70974 15.7766 1.6803 10.5192 1.6803C5.2619 1.6803 1 5.70974 1 10.6803C1 15.6509 5.2619 19.6803 10.5192 19.6803Z" />
    <path d="M10.5192 12.4803C12.6222 12.4803 14.3269 10.8685 14.3269 8.88027C14.3269 6.89205 12.6222 5.28027 10.5192 5.28027C8.41631 5.28027 6.71155 6.89205 6.71155 8.88027C6.71155 10.8685 8.41631 12.4803 10.5192 12.4803Z" />
    <path d="M16.7221 17.5071C16.1467 16.4174 15.2616 15.5007 14.166 14.8601C13.0703 14.2194 11.8076 13.88 10.5193 13.88C9.23107 13.88 7.96831 14.2194 6.87269 14.8601C5.77707 15.5007 4.89194 16.4174 4.31659 17.5071" />
  </svg>
);

export default ProfileIcon;
