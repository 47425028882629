const BedIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.30259 7.875H14.3515C14.697 7.875 15.0283 8.0506 15.2726 8.36316C15.5169 8.67572 15.6541 9.09964 15.6541 9.54167V13.5H1V9.54167C1 9.09964 1.13724 8.67572 1.38152 8.36316C1.6258 8.0506 1.95712 7.875 2.30259 7.875V7.875Z" />
      <path d="M1 13.5V16" />
      <path d="M15.6541 13.5V16" />
      <path d="M14.1887 7.875V2.25C14.1887 1.91848 14.0858 1.60054 13.9026 1.36612C13.7194 1.1317 13.4709 1 13.2118 1H3.44236C3.18326 1 2.93477 1.1317 2.75156 1.36612C2.56834 1.60054 2.46542 1.91848 2.46542 2.25V7.875" />
      <path d="M6.04753 4.75H10.6066C10.7793 4.75 10.945 4.8378 11.0671 4.99408C11.1893 5.15036 11.2579 5.36232 11.2579 5.58333V7.875H5.39624V5.58333C5.39624 5.36232 5.46486 5.15036 5.587 4.99408C5.70914 4.8378 5.8748 4.75 6.04753 4.75V4.75Z" />
    </svg>
  );
};

export default BedIcon;
