const logout: () => Promise<any> = () => {
  return fetch('/api/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
};

export default logout;
