import { cn } from 'lib/cn';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  progressPercentage: number;
  light?: boolean;
}

const ProgressBar = ({ progressPercentage, light, ...props }: Props) => (
  <div
    {...props}
    className={cn('h-3 relative max-w-xl rounded-full overflow-hidden', props.className)}
  >
    <div
      className={cn('w-full h-full absolute', light ? 'bg-black bg-opacity-25' : 'bg-gray-200')}
    />
    <div
      className={cn('h-full absolute', light ? 'bg-white' : 'bg-blue-500')}
      style={{ width: `${progressPercentage}%` }}
    />
  </div>
);

export default ProgressBar;
