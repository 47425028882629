const MessageIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.75 15.0625H8.875L4.375 18.4375V15.0625H2.125C1.82663 15.0625 1.54048 14.944 1.3295 14.733C1.11853 14.522 1 14.2359 1 13.9375V2.6875C1 2.38913 1.11853 2.10298 1.3295 1.892C1.54048 1.68103 1.82663 1.5625 2.125 1.5625H16.75C17.0484 1.5625 17.3345 1.68103 17.5455 1.892C17.7565 2.10298 17.875 2.38913 17.875 2.6875V13.9375C17.875 14.2359 17.7565 14.522 17.5455 14.733C17.3345 14.944 17.0484 15.0625 16.75 15.0625Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 8C5.80222 8 5.60888 8.05865 5.44443 8.16853C5.27998 8.27841 5.15181 8.43459 5.07612 8.61732C5.00043 8.80004 4.98063 9.00111 5.01922 9.19509C5.0578 9.38907 5.15304 9.56726 5.29289 9.70711C5.43275 9.84696 5.61093 9.9422 5.80491 9.98078C5.99889 10.0194 6.19996 9.99957 6.38268 9.92388C6.56541 9.84819 6.72159 9.72002 6.83147 9.55557C6.94135 9.39112 7 9.19778 7 9C7 8.73478 6.89464 8.48043 6.70711 8.29289C6.51957 8.10536 6.26522 8 6 8Z" />
    <path d="M9.375 8C9.17722 8 8.98388 8.05865 8.81943 8.16853C8.65498 8.27841 8.52681 8.43459 8.45112 8.61732C8.37543 8.80004 8.35563 9.00111 8.39422 9.19509C8.4328 9.38907 8.52804 9.56726 8.66789 9.70711C8.80774 9.84696 8.98593 9.9422 9.17991 9.98078C9.37389 10.0194 9.57496 9.99957 9.75768 9.92388C9.94041 9.84819 10.0966 9.72002 10.2065 9.55557C10.3164 9.39112 10.375 9.19778 10.375 9C10.375 8.73478 10.2696 8.48043 10.0821 8.29289C9.89457 8.10536 9.64022 8 9.375 8Z" />
    <path d="M12.75 8C12.5522 8 12.3589 8.05865 12.1944 8.16853C12.03 8.27841 11.9018 8.43459 11.8261 8.61732C11.7504 8.80004 11.7306 9.00111 11.7692 9.19509C11.8078 9.38907 11.903 9.56726 12.0429 9.70711C12.1827 9.84696 12.3609 9.9422 12.5549 9.98078C12.7489 10.0194 12.95 9.99957 13.1327 9.92388C13.3154 9.84819 13.4716 9.72002 13.5815 9.55557C13.6914 9.39112 13.75 9.19778 13.75 9C13.75 8.73478 13.6446 8.48043 13.4571 8.29289C13.2696 8.10536 13.0152 8 12.75 8Z" />
  </svg>
);

export default MessageIcon;
